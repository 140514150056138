import React from "react"
import SEO from "../components/seo"
import "./index.scss"
import { LightFooter } from "../components/Footer"
import Container from "../components/Container"
import Header from "../components/Header"
import styled, { ThemeProvider } from "styled-components"
import {
  Banner,
  BannerText,
  BannerContent,
  BannerContentLeft,
} from "../components/Banner"
import { PageTitle } from "../components/Typography"
import { darkTheme } from "src/utils/themes"

const Label = styled.span`
  background: #ffffff;
  border-radius: 5px;
  color: #34b6c3;
  text-align: center;
  padding: 4px 13px;
  display: inline-block;
`

const IndexPageNew = ({ location }) => (
  <ThemeProvider theme={darkTheme}>
    <div>
      <Banner>
        <Container>
          <SEO title="Archimydes" location={location} />
          <Header />
          <BannerContent>
            <BannerContentLeft>
              <Label>Error</Label>
              <PageTitle>404</PageTitle>
              <BannerText>This page does not exist</BannerText>
            </BannerContentLeft>
          </BannerContent>
        </Container>
      </Banner>
      <div></div>
      <div style={{ marginTop: "120px" }}>
        <LightFooter />
      </div>
    </div>
  </ThemeProvider>
)

export default IndexPageNew
